@import '../../../../scss/theme-bootstrap';

$width-units: 10 20 30 40 50 60 70 80 90 100;
$percentage-unit: unquote('%');

.hero-block {
  @include container-max-width-x-large;
  border: none;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  position: relative;
  &__content,
  &__content-wrapper,
  &__content-over-media,
  &__media-wrapper,
  &__content-below-media {
    display: flex;
    flex-direction: column;
  }
  &__media {
    display: block;
    height: auto;
    width: 100%;
  }
  &__media-wrapper {
    position: relative;
    text-align: center;
    &.playing-video {
      & + .hero-block__content-over-media-wrapper {
        display: none;
      }
    }
  }
  &__content-above-media,
  &__content-over-media,
  &__content-below-media {
    .hero-block__links {
      width: 100%;
      @media #{$cr19-large-up} {
        width: auto;
      }
    }
  }
  &__content-over-media {
    @include container-padding-rules;
    z-index: 9;
    position: absolute;
    top: 0;
    bottom: 0;
    &-wrapper {
      display: flex;
      flex-direction: column;
    }
    .hero-block-no-media & {
      position: relative;
    }
    .playing-video & {
      @media #{$cr19-large-up} {
        display: none;
      }
    }
  }
  &__content-media {
    z-index: -1;
  }
  &__content-text {
    display: flex;
    flex-direction: column;
    .content-block__line {
      &--header {
        padding-bottom: 15px;
      }
    }
  }
  &__links {
    &--stacked {
      @media #{$cr19-large-up} {
        flex-direction: column;
        > div:first-of-type {
          margin: 0 0 20px;
        }
      }
    }
  }
  &__link {
    @each $value in $width-units {
      &-width-#{$value} {
        .basic_cta_partial {
          width: #{$value}$percentage-unit;
        }
      }
    }
  }
  // Helpers
  .content-block__content {
    display: block;
    height: auto;
    flex: 0;
  }
  .content-block__line {
    display: inline-block;
  }
  p {
    line-height: inherit;
    margin: 0;
  }
  .mantle-custom-text {
    text-align: unset;
  }
  .button {
    &--dark:hover {
      color: $color-white;
    }
    &--light:hover {
      color: $color-black;
    }
  }
  div.pc-hidden {
    @media #{$cr19-large-up} {
      display: none;
    }
  }
  .mobile-hidden {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
  }
  .mobile-flex-hidden {
    display: none;
    @media #{$cr19-large-up} {
      display: flex;
    }
  }
  .mantle-media-asset {
    height: auto;
    line-height: 0;
  }

  // Layout Options
  &--flip-layout {
    display: flex;
    flex-direction: column-reverse;
  }
  &--flip-layout-mobile {
    display: flex;
    flex-direction: column-reverse;
    @media #{$cr19-large-up} {
      flex-direction: column;
    }
  }
  &--flip-layout-desktop {
    display: flex;
    flex-direction: column;
    @media #{$cr19-large-up} {
      flex-direction: column-reverse;
    }
  }
  &--force-full-width {
    #{$ldirection}: 50%;
    #{$rdirection}: 50%;
    margin-#{$ldirection}: -50vw;
    margin-#{$rdirection}: -50vw;
    max-width: none;
    position: relative;
    width: 100vw;
  }
  div.hero-block--mobile-hidden {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
  }
  div.hero-block--pc-hidden {
    @media #{$cr19-large-up} {
      display: none;
    }
  }
}
